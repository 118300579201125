const translations = {
    en: {
        translation: {
            "welcome": "Welcome to React and react-i18next",
            "navbar": {
                "official-links": "Official Links",
                "enterprises": "Enterprises",
                "video-technology-partners": 'AI Technology Partners',
                "content-and-media-partners": 'Content & Media Partners',
                "validator-partners": 'Validator Partners',
                "developers": "Devs",
                'resources': 'Resources',
                'metachain-overview': "Metachain Overview",
                "community": "Community",
                'theta-edge-network': 'Theta Edge Network',
                'earn-stake-trade': 'Earn, Stake & Trade',
                'product-roadmap': 'Product Roadmap',
                "explorer": "Explorer",
                "wallet": "Wallet",
                "about": "About",
                "docs": "Docs",
                "support": "Support",
                'ecosystem': 'Ecosystem',
                "security": "Security",
            },
            "home": {
                "main-title-reinventing": "Reinventing",
                "main-title-tagline-1": "AI at the Edge",
                "main-title-tagline-2": "GPU as currency of the future",
                "main-title-tagline-3": "DePIN: resource sharing economy",
                "main-title-tagline-4": "Rendering with 3D GenAI",
                "main-title-tagline-5": "Video Web3 Infrastructure",
                "main-subtitle": "The Decentralized Cloud for\nAI, Media & Entertainment",
                'edgecloud-title': 'The major upgrade to\nTheta Elite Booster nodes is here',
                'edgecloud-launch-date': 'June 26th, 2024',
                'edgecloud-action-button': 'Visit Dashboard for AI Developers ->',
                "mainnet-4-launching": 'MAINNET 4.0 LAUNCHED 2023',
                'edgenode-mobile-launch-date': 'Sept 25, 2024',
                'theta-video-infrastructure': 'Theta Video Infrastructure',
                'recent-news': 'Recent News',
                'view-all-news': 'View all news →',
                'view-more-news': 'View more ↓',
                'view-less-news': 'View less ↑',
                'live-nodes': 'Live Nodes',
            },
            "infrastructure": {
                "video-api": {
                    "title": "Web3 Video API",
                    "subtitle": "Lower your cost to encode, store and deliver video"
                },
                "metachain": {
                    "title": "Metachain",
                    "subtitle": "Customizable, highly scalable blockchain for your Web3 business"
                },
                "edge-network": {
                    "title": "Global Edge Network",
                    "subtitle": "Share bandwidth, storage and computing to earn rewards"
                },
                "nft-drm": {
                    "title": "NFTs with DRM",
                    "subtitle": "Monetize your content and IP, secure with digital rights"
                },
                "validators": {
                    "title": "Enterprise Validators",
                    "subtitle": "Join global leaders to secure and govern Theta network"
                },
            },
            "enterprises": {
                'vtp-section': {
                    'title': 'AI Developers & Teams',
                    'subtitle': 'Join leading AI enterprises and universities using EdgeCloud for AI model training and inference',
                    'point-1': 'Theta EdgeCloud delivers the best price-to-performance and the fastest sign-up to direct access to GPU nodes/clusters via SSH.',
                    'point-2': 'Leverage the power of 30,000+ distributed Nvidia 3090/4090s nodes for inference and training.',
                    'point-3': 'On-demand enterprise grade Nvidia A100/H100 and more at 50-70% cost savings.',
                    'cta-1': 'ThetaEdgeCloud.com',
                    'cta-2': 'Theta EdgeCloud AI docs →',
                    'logos': {
                        'vtp': 'AI Technology Partners'
                    }
                },
                'cmp-section': {
                    'title': 'Content & Media Partners',
                    'subtitle': 'Expand beyond ad-supported, subscription and pay-per-view revenue into Web3 NFT ownership and membership models.',
                    'point-1': 'Launch into the world of Web3 and Metaverse with Theta’s NFT-based Digital Rights Management (DRM).',
                    'point-2': 'Leverage ThetaDrop end-to-end full service NFT platform to increase fan engagement and customer loyalty.',
                    'point-3': 'Built on Theta’s patent #17/218,245, implement DRM via NFTs in a decentralized network.',
                    'cta-1': 'ThetaDrop.com',
                    'cta-2': 'NFT-based DRM documentation →',
                    'logos': {
                        'cmp': 'Content and Media Partners'
                    }
                },
                'vp-section': {
                    'title': 'Theta Validator Partners',
                    'subtitle': 'Join global partners to help secure and govern the leading media blockchain in the world.',
                    'point-1': 'Join Global 100 corporations to help secure, govern and decentralize Theta network.',
                    'point-2': 'Be on the cutting edge of Web3 and blockchain technologies, participate in the leading public blockchain for media & entertainment.',
                    'point-3': 'Earn TFUEL rewards by staking THETA.',
                    'cta-1': 'Theta Validator Documentation',
                    'logos': {
                        'mt': 'Media and Technology',
                        'bc': 'Blockchain',
                        'ii': 'Institutional Investors',
                    }
                }
            },
            "developers": {
                "title": "What is Theta Network?",
                "subtitle": "Theta is the leading decentralized cloud for AI, media and entertainment. [Theta EdgeCloud](https://thetaedgecloud.com/) is the first hybrid cloud-edge computing AI platform, powered by the Theta Edge Network with over 30,000 distributed global edge nodes and 80 PetaFLOPS of always available distributed GPU compute power. Theta is among the top 10 DePIN blockchains by market capitalization listed on both [Coinmarketcap](https://coinmarketcap.com/view/depin/) and [Coingecko](https://www.coingecko.com/en/categories/depin), and among the top [AI tokens on Binance.com](https://www.binance.com/en/markets/coinInfo-AI).\n\n[EdgeCloud Video](https://www.thetaedgecloud.com/dashboard/video/overview) is a turn-key Web3 video API for developers offering significantly lower video transcoding and content delivery costs. Theta’s Web3 infrastructure including [ThetaDrop](https://www.thetadrop.com/) NFT marketplace and [Theta Web3 Theatre](https://www.thetaedgecloud.com/dashboard/video/web3theatre) enable entertainment companies to drive new revenues and community engagement.",
                "cta-1": "Learn more",
                "cards": {
                    "smart-contract": {
                        'title': 'Smart contract and DApp development',
                        'subtitle': 'Learn how to deploy EVM-compatible smart contracts and Dapps on Theta using Remix, Hardhat, and Truffle Suite',
                        'cta-title': 'View Documentation →'
                    },
                    "tnt20": {
                        'title': 'Theta Network TNT20 Token Tutorial',
                        'subtitle': 'Launch your own TNT-20 token in minutes',
                        'cta-title': 'View Tutorial →'
                    },
                    "github": {
                        'title': 'Theta Labs Github',
                        'subtitle': 'Collaborate on the open-source Theta blockchain with the Theta Labs Git repositories.',
                        'cta-title': 'View GitHub →'
                    },
                    "ecosystem": {
                        'title': 'Theta Ecosystem',
                        'subtitle': 'Learn about the Web3 businesses, video platforms, NFT projects, and more that are building on Theta blockchain.',
                        'cta-title': 'View Projects →'

                    }
                },
                "logos": {
                    "p": "Partners"
                }
            },
            "ecosystem": {
                "title": "Built on Theta",
                "subtitle": "Learn about the Web3 businesses, video platforms, NFT projects, and more that are building on Theta blockchain.",
            },
            "community": {
                "title": "Join the Theta community",
                'cta-1': 'Learn more',
                "lns-section": "Live Network Stats",
                "en-section": "Theta Edge Network",
                "eas-section": "Join the Network - Earn & Stake",
                "t-section": "Trade",
                "pr-section": "Product Roadmap"
            },
            "join-the-network": {
                "title": "Join the Network",
            },
            "earning": {
                "edge-node": {
                    'kind': 'Earn',
                    'title': 'Earn TFUEL simply by sharing your PC, Mac and Linux box resources',
                    'subtitle': '(storage, compute and bandwidth)',
                    'cta': 'Theta Edge Node'
                },
                "guardian-node": {
                    'kind': 'Stake',
                    'title': 'Become a Theta Guardian by staking THETA to earn TFUEL',
                    'subtitle': 'Help secure and participate in the Theta Network.',
                    'cta': 'Theta Guardian Node'
                },
                "elite-edge-node": {
                    'kind': 'Stake',
                    'title': 'Power up to an Elite Edge node by staking TFUEL to earn more TFUEL',
                    'subtitle': 'Be part of an elite group of 8,000 edge operators, top 6%',
                    'cta': 'Theta Edge Node'
                },
                "tdrop": {
                    'kind': 'Stake',
                    'title': 'Earn Theta’s NFT liquidity mining token TDROP by staking TDROP',
                    'subtitle': 'Two simple steps from within Theta wallet.',
                    'cta': 'Theta Wallet'
                }
            },
            "roadmap": {
                "col1": {
                    'title': '2024 H1',
                    'items': [
                        'EdgeCloud, next gen edge network, release 1',
                        [
                            'Cloud hosted nodes managed by Theta',
                            'Elite+ Booster features for EEN',
                            'Operations dashboard, virtual management',
                            'Host popular gen-AI, vision and language models'
                        ],
                        'Theta Hackathon - Metachain/EdgeCloud/DePIN focus',
                        'New adoption metrics and subchain stats',
                        'Support new ecosystem projects, subchains, TNT20 staking',
                        'Theta Video API enhancements',
                        [
                            'Self-serve studio-level NFT DRM support for livestreams and VoD',
                            'Advanced metrics dashboard'
                        ],
                    ]
                },
                "col2": {
                    'title': '2024 H2',
                    'items': [
                        'EdgeCloud, next gen edge network, release 2',
                        [
                            'Enable partners to setup and run hosted nodes in their own cloud, data centers',
                            'Support for advanced job prioritization and opt-out',
                            'Summary reports / analytics of jobs completed',
                            'Support distributed temporal and persistent storage for compute jobs'
                        ],
                        'Fully decentralized Theta Video API for DRM, transcoding and delivery',
                        'Next gen Theta Web3 Theater integrated into ThetaDrop and partner platforms',
                        'Extend TDROP use cases to partner marketplaces',
                    ]
                },
                "col3": {
                    'title': '2025+',
                    'items': [
                        'EdgeCloud, next gen edge network, release 3',
                        [
                            'Deploy first hybrid cloud computing platform built on a fully distributed edge architecture',
                            'Jobs and payload marketplace',
                            'Optimization of jobs supply & demand',
                            'Developer API interface',
                        ]
                    ]
                }
            },
            "wallet": {
                'title': 'Store your THETA, TFUEL and TDROP tokens securely and easily',
                'subtitle': 'Send, receive and earn with a variety of wallets in the Theta ecosystem.',
                'platforms-section': {
                    'title': 'Supported Wallet Platforms'
                }
            },
            "official-links": {
                "title": "Official Links",
                "subtitle": "At THETA, your security is our top priority. We've noticed an increase in reports of scams and phishing attempts, and we want to share our official websites.\n\nBeware of unsolicited messages from senders who pretend to be someone you trust. The THETA team will never call you, send an unsolicited direct message via text or social media, ask you to send your funds to an external wallet address or ask you for your account password or security codes.",
            },
            "docs": {
                'title': 'Build on Theta',
                'subtitle': 'Learn how to launch smart contracts, run nodes, and develop Dapps on Theta blockchain',
                'papers-section': {
                    'title': 'Library of Papers'
                },
                'main-docs': {
                    'what-is-theta': {
                        'title': 'What is Theta?',
                        'subtitle': 'Next generation media and entertainment focused blockchain',
                        'cta': {
                            'title': 'View Documentation →'
                        }
                    },
                    'mn4': {
                        'title': 'Mainnet 4.0 Whitepaper',
                        'subtitle': 'Introducing Theta Metachain to power Web3 Businesses',
                        'cta': {
                            'title': 'View Whitepaper →'
                        }
                    }
                }
            },
            "about": {
                'title': 'Guided by experience in AI, media and entertainment',
                'subtitle': 'The Theta team is made up of experienced technologists and executives from Salesforce, Netflix, Amazon, Samsung, Vimeo, and Google. Theta’s unique combination of AI + blockchain expertise are the key to empowering AI and video teams with unrivaled GPU price-to-performance.\n\nTheta is led by CEO Mitch Liu, co-founder of Gameview Studios (acquired by DeNA) and Tapjoy (acquired by ironSource), and CTO Jieyi Long, co-founder of MadSkill Game Studios. Collectively they hold over a [dozen patents](https://patents.google.com/?inventor=jieyi+long&oq=jieyi+long&sort=new) in AI, blockchain, video, digital rights management and more.',
                'leadership-team-section': {
                    'title': 'Leadership Team'
                },
                'media-advisors-section': {
                    'title': 'Media Advisors'
                },
                'blockchain-advisors-section': {
                    'title': 'AI & Blockchain Advisors'
                },
                'investors-section': {
                    'title': 'Investors'
                }
            },
            "papers": {
                "multi-bft": {
                    'title': 'Multi-BFT paper',
                    'subtitle': 'Modified BFT consensus mechanism allowing 1000s of nodes to participate while supporting very high transaction throughput.',
                    'cta': 'View Paper →'
                },
                "tdrop": {
                    'title': 'TDROP Whitepaper',
                    'subtitle': 'The governance token of ThetaDrop NFT marketplace, powering NFT Liquidity Mining.',
                    'cta': 'View Paper →'
                },
                "mn3": {
                    'title': '3.0 Whitepaper',
                    'subtitle': 'Cryptoeconomic design of the TFUEL token, including TFUEL burning and staking.',
                    'cta': 'View Paper →'
                },
                "mn2": {
                    'title': '2.0 Whitepaper',
                    'subtitle': 'Core features of the Theta blockchain including the Aggregated Signature Gossip Scheme and Resource Oriented Micropayment Pool.',
                    'cta': 'View Paper →'
                }
            },
            "common": {
                'learn-more': 'Learn more →',
                'view-article': 'View Article →',
                'view-project': 'View Project →',
                'edge-node-with-line-break': 'Active Edge\nNode',
                'guardian-node-with-line-break': 'Guardian\nNode',
                'validator-node-with-line-break': 'Validator\nNode',
                'edge-node': 'Edge Node',
                'guardian-node': 'Guardian Node',
                'validator-node': 'Validator Node',
            },
            "quotes": {
                "caa": {
                    'body': 'We believe that Web3 and blockchain technologies will bring unprecedented opportunities to our family of storytellers, trendsetters and icons in the entertainment industry. We’ve been impressed with Theta’s achievements since our seed investment in 2016 and look forward to supporting the project in the years ahead.',
                    'name': 'Michael Yanover, Head of Business Development'
                },
                "wpt": {
                    "body": '“World Poker Tour is excited to fully integrate Theta network peer-to-peer streaming infrastructure on our new Theta powered channel on WPT.com and provide millions of our global fans with a superior viewer experience,” said Adam Pliska, CEO of the World Poker Tour. “We view this as a strategic long-term opportunity in the industry and we believe it will contribute to additional user growth.”'
                },
                "cinedigm": {
                    "body": "Cinedigm's senior vice president of products and technology, Tony Huidor, said that the company views the partnership with Theta as a long-term strategic opportunity. It’s a key differentiator in the industry and we believe it will contribute to sustained viewer growth and user engagement in the premium OTT segment."
                },
                "jukin": {
                    "body": "“We’re thrilled to expand our relationship with Theta across multiple fronts with this new partnership,” said Cameron Saless, chief business officer of Trusted Media Brands. “We see value in the Theta Network, their decentralized video infrastructure, and blockchain technology. We are also very excited to partner with them to bring digital collectible products to the FailArmy and The Pet Collective communities.”"
                },
                "american-idol": {
                    "body": "“At American Idol, we are constantly challenging ourselves to be on the forefront of innovation,” says Megan Michaels Wolflick, the series’ Showrunner, and Executive Producer. “With interactive NFT trading cards celebrating our 20th season, fans will be able to immerse themselves even more into the Idol experience, as they collect their favorite contestants’ cards, and follow their progress throughout the competition.”"
                },
                "samsung": {
                    "body": "“Theta's native blockchain operates in the POS proof-of-stake method that minimizes the energy generated in the process of minting NFTs, thereby reducing the burden of destroying the environment after issuing massive amounts of NFTs. As a result of the campaign, 110,000 Samsung Galaxy S22 and 20,000 Galaxy Tabs S8 pre-order customers received NFTs for a total of 130,000 in the Korea market and contributed to Korea's best ever pre-order sales.” -- Shinwoo Lee, Director of Samsung Electronics"
                },
                "price-is-right": {
                    "body": "“For our first The Price Is Right NFTs, we needed to make sure we had the right platform to make the debut a success” said Angela Hueber, Vice President of Commercial and Licensing at Fremantle. “Theta’s low-cost, green NFT platform stood out as the best choice, and their previous sold out NFT drops with some of the most well-known brands and artists reassured us that it was the right home for our legendary series.”"
                },
                "google": {
                    "body": "“We’re impressed by Theta’s achievements in blockchain video & data delivery. We look forward to participating as an enterprise validator, and to providing Google Cloud infrastructure in support of Theta’s long-term mission & future growth.” — Allen Day, Google Cloud Developer advocate"
                },
                "sony": {
                    "body": "“At R&D Center we share Theta Network’s passion towards decentralized technologies, and are excited about the possibilities which Blockchain technologies can offer to creators and can provide effective tools for license and rights management.\" -- Magdalena Wasowska, Head of Sony Europe R&D Center Europe Brussels Laboratory"
                },
                "replay": {
                    "body": "“We’re incredibly excited to remove the monetization friction for content creators in partnership with Theta Labs, who has been a clear visionary in this space. By delivering unparalleled transparency into viewership and performance, we’re excited to reward both the end users and creators powering the content economy.” - Krish Arvapally, CEO, Founder of Replay"
                },
                "opentheta": {
                    "body": "\"At OpenTheta we utilize the Theta blockchain for our NFT marketplace, since we believe in Theta's decentralized, eco-friendly video delivery.\n Through Theta's technologies such as Digital Rights Management (DRM) and the Theta Video API you can unlock videos exclusively with Theta NFTs. These unique features provide a utility only found on the Theta blockchain.\" - Simon Piazolo, founder OpenTheta."
                },
                "passaways": {
                    "body": "As a generative NFT project with a collection of 9,666 videos, the Theta Video API was our first choice. Theta being EVM compatible has made our developer experience frictionless while building our Smart Contracts for $PLASM (TNT20 token) and NFT staking. We look forward to continuing to build our Passaway ecosystem & community on Theta!"
                }
            }
        }
    }
};


export default translations;