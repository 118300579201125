import classNames from "classnames";

const HeroContent = ({category, title, subtitle, color}) => {
    return (
        <div className={classNames('HeroContent', `HeroContent--${color}`)}>
            <div className={'HeroContent__category'}>
                {category}
            </div>
            <div className={'HeroContent__title'}>
                {title}
            </div>
            {
                subtitle &&
                <div className={'HeroContent__subtitle'}>
                    {subtitle}
                </div>
            }
        </div>
    )
};

export default HeroContent;