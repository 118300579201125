import {Trans, useTranslation} from "react-i18next";
import PageBackground from "../components/PageBackground";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";

import aboutBackground from '../assets/backgrounds/about.jpg';

import ltHeadshotMitch from '../assets/team/mitch@2x.png';
import ltHeadshotJieyi from '../assets/team/jieyi@2x.png';
import ltHeadshotWes from '../assets/team/wes@2x.png';
import ltHeadshotAndrea from '../assets/team/andrea@2x.png';
import ltHeadshotJerry from '../assets/team/jerry@2x.png';
import ltHeadshotJayne from '../assets/team/jayne@2x.png';
import ltHeadshotOwen from '../assets/team/owen@2x.png';
import ltHeadshotWill from '../assets/team/will@2x.png';
import ltHeadshotKyle from '../assets/team/kyle@2x.png';

import maHeadshotSteve from '../assets/media-advisors/steve@2x.png';
import maHeadshotDennis from '../assets/media-advisors/dennis@2x.png';
import maHeadshotCliff from '../assets/media-advisors/cliff@2x.png';
import maHeadshotSam from '../assets/media-advisors/sam@2x.png';
import maHeadshotJustin from '../assets/media-advisors/justin@2x.png';
import maHeadshotKaren from '../assets/media-advisors/karen@2x.png';
import maHeadshotKyle from '../assets/media-advisors/kyle@2x.png';
import maHeadshotJonathan from '../assets/media-advisors/jonathan@2x.png';

import maLogoSteve from '../assets/media-advisors/steve-logo@2x.png';
import maLogoDennis from '../assets/media-advisors/dennis-logo@2x.png';
import maLogoCliff from '../assets/media-advisors/cliff-logo@2x.png';
import maLogoSam from '../assets/media-advisors/sam-logo@2x.png';
import maLogoJustin from '../assets/media-advisors/justin-logo@2x.png';
import maLogoKaren from '../assets/media-advisors/karen-logo@2x.png';
import maLogoKyle from '../assets/media-advisors/kyle-logo@2x.png';
import maLogoJonathan from '../assets/media-advisors/jonathan-logo@2x.png';

import baHeadshotDovey from '../assets/blockchain-advisors/dovey@2x.png';
import baHeadshotTravis from '../assets/blockchain-advisors/travis@2x.png';
import baHeadshotFan from '../assets/blockchain-advisors/fan@2x.png';
import baHeadshotMa from '../assets/blockchain-advisors/ma@2x.png';
import baHeadshotRiz from '../assets/blockchain-advisors/riz@2x.png';
import baHeadshotZhen from '../assets/blockchain-advisors/zhen@2x.png';
import baHeadshotMark from '../assets/blockchain-advisors/mark@2x.png';
import baLogoZhen from '../assets/blockchain-advisors/zhen-logo@2x.png';
import baLogoDovey from '../assets/blockchain-advisors/dovey-logo@2x.png';
import baLogoTravis from '../assets/blockchain-advisors/travis-logo@2x.png';
import baLogoFan from '../assets/blockchain-advisors/fan-logo@2x.png';
import baLogoMa from '../assets/blockchain-advisors/ma-logo@2x.png';
import baLogoRiz from '../assets/blockchain-advisors/riz-logo@2x.png';
import baLogoMark from '../assets/blockchain-advisors/mark-logo@2x.png';

import invLogo1 from '../assets/investors/1@2x.png';
import invLogo2 from '../assets/investors/2@2x.png';
import invLogo3 from '../assets/investors/3@2x.png';
import invLogo4 from '../assets/investors/4@2x.png';
import invLogo5 from '../assets/investors/5@2x.png';
import invLogo6 from '../assets/investors/6@2x.png';
import invLogo7 from '../assets/investors/7@2x.png';
import invLogo8 from '../assets/investors/8@2x.png';
import invLogo9 from '../assets/investors/9@2x.png';
import invLogo10 from '../assets/investors/10@2x.png';
import invLogo11 from '../assets/investors/11@2x.png';
import invLogo12 from '../assets/investors/12@2x.png';
import invLogo13 from '../assets/investors/13@2x.png';
import invLogo14 from '../assets/investors/14@2x.png';
import invLogo15 from '../assets/investors/15@2x.png';
import invLogo16 from '../assets/investors/16@2x.png';
import invLogo17 from '../assets/investors/17@2x.png';
import invLogo18 from '../assets/investors/18@2x.png';
import invLogo19 from '../assets/investors/19@2x.png';
import invLogo20 from '../assets/investors/20@2x.png';
import invLogo21 from '../assets/investors/21@2x.png';
import invLogo22 from '../assets/investors/22@2x.png';
import invLogo23 from '../assets/investors/23@2x.png';
import invLogo24 from '../assets/investors/24@2x.png';


import PersonCard from "../components/PersonCard";
import PartnerLogos from "../components/PartnerLogos";
import Markdown from "react-markdown";

const AboutPage = () => {
    const {t} = useTranslation();

    return (
        <div className={'Page AboutPage'}>
            <PageSection>
                <PageBackground src={aboutBackground}/>
                <HeroContent color={'teal'}
                             category={t('navbar.about')}
                             title={t('about.title')}
                             subtitle={<Markdown>{t('about.subtitle')}</Markdown>}
                />

                <div className={'AboutPage__leadership-team-section'}>
                    <SectionTitle title={t('about.leadership-team-section.title')}/>
                    <div className={'PersonCards'}>
                        <PersonCard name={'Mitch Liu'}
                                    title={'Cofounder / CEO'}
                                    headshot={ltHeadshotMitch}
                        />
                        <PersonCard name={'Jieyi Long'}
                                    title={'Cofounder / CTO'}
                                    headshot={ltHeadshotJieyi}
                        />
                        <PersonCard name={'Wes Levitt'}
                                    title={'Head of Strategy'}
                                    headshot={ltHeadshotWes}
                        />
                        <PersonCard name={'Andrea Berry'}
                                    title={'Head of Business Development'}
                                    headshot={ltHeadshotAndrea}
                        />
                        <PersonCard name={'Jerry Kowal'}
                                    title={'Head of Content'}
                                    headshot={ltHeadshotJerry}
                        />
                        {/*<PersonCard name={'Jayne Kim'}*/}
                        {/*            title={'General Manager - Asia'}*/}
                        {/*            headshot={ltHeadshotJayne}*/}
                        {/*/>*/}
                        <PersonCard name={'Owen Ching'}
                                    title={'Head of Cloud Operations'}
                                    headshot={ltHeadshotOwen}
                        />
                        <PersonCard name={'Will Norling'}
                                    title={'Head of Finance & Tax'}
                                    headshot={ltHeadshotWill}
                        />
                        <PersonCard name={'Kyle Laffey'}
                                    title={'Head of Partnerships'}
                                    headshot={ltHeadshotKyle}
                        />
                    </div>
                </div>

                <div className={'AboutPage__blockchain-advisors-section'}>
                    <SectionTitle title={t('about.blockchain-advisors-section.title')}/>
                    <div className={'PersonCards'}>
                        <PersonCard name={'Dovey Wan'}
                                    title={'Co-founder'}
                                    headshot={baHeadshotDovey}
                                    logo={baLogoDovey}
                        />
                        <PersonCard name={'Travis Skweres'}
                                    title={'Founder'}
                                    headshot={baHeadshotTravis}
                                    logo={baLogoTravis}
                        />
                        <PersonCard name={'Fan Zhang'}
                                    title={'Co-founder'}
                                    headshot={baHeadshotFan}
                                    logo={baLogoFan}
                        />
                        <PersonCard name={'Ma Haobo'}
                                    title={'CEO'}
                                    headshot={baHeadshotMa}
                                    logo={baLogoMa}
                        />
                        <PersonCard name={'Riz Virk'}
                                    title={'Executive Director'}
                                    headshot={baHeadshotRiz}
                                    logo={baLogoRiz}
                        />
                        <PersonCard name={'Zhen Xiao'}
                                    title={'Prof of Computer Science'}
                                    headshot={baHeadshotZhen}
                                    logo={baLogoZhen}
                        />
                        {/*<PersonCard name={'Mark Deschaine'}*/}
                        {/*            title={'Associate Professor of Educational Leadership'}*/}
                        {/*            headshot={baHeadshotMark}*/}
                        {/*            logo={baLogoMark}*/}
                        {/*/>*/}
                    </div>
                </div>


                <div className={'AboutPage__media-advisors-section'}>
                    <SectionTitle title={t('about.media-advisors-section.title')}/>
                    <div className={'PersonCards'}>
                        <PersonCard name={'Steve Chen'}
                                    title={'Co-founder'}
                                    headshot={maHeadshotSteve}
                                    logo={maLogoSteve}
                        />
                        <PersonCard name={'Dennis Fong'}
                                    title={'Founder'}
                                    headshot={maHeadshotDennis}
                                    logo={maLogoDennis}
                        />
                        <PersonCard name={'Clifford Morgan'}
                                    title={'CEO'}
                                    headshot={maHeadshotCliff}
                                    logo={maLogoCliff}
                        />
                        <PersonCard name={'Sam Wick'}
                                    title={'Head of Ventures'}
                                    headshot={maHeadshotSam}
                                    logo={maLogoSam}
                        />
                        <PersonCard name={'Justin Kan'}
                                    title={'Cofounder'}
                                    headshot={maHeadshotJustin}
                                    logo={maLogoJustin}
                        />
                        <PersonCard name={'Karen Huh'}
                                    title={'Senior VP'}
                                    headshot={maHeadshotKaren}
                                    logo={maLogoKaren}
                        />
                        <PersonCard name={'Kyle Okamoto'}
                                    title={'Chief Network Officer'}
                                    headshot={maHeadshotKyle}
                                    logo={maLogoKyle}
                        />
                        <PersonCard name={'Jonathan Wong'}
                                    title={'Director of Product'}
                                    headshot={maHeadshotJonathan}
                                    logo={maLogoJonathan}
                        />
                    </div>
                </div>

                <div className={'AboutPage__investors-section'}>
                    <SectionTitle title={t('about.investors-section.title')}/>
                    <PartnerLogos logos={[
                        invLogo1, invLogo2, invLogo3, invLogo4, invLogo5, invLogo6,
                        invLogo7, invLogo8, invLogo9, invLogo10, invLogo11, invLogo12,
                        invLogo13, invLogo14, invLogo15, invLogo16, invLogo17, invLogo18,
                        invLogo19, invLogo20, invLogo21, invLogo22, invLogo23, invLogo24,
                    ]}/>
                </div>
            </PageSection>
        </div>
    )
};

export default AboutPage;