import {TextLinkButton} from "./Button";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

export const Quote = ({body, name, logo, center, className}) => {
    const { t } = useTranslation();

    return (
        <div className={ classNames('Quote', className, {
            'Quote--center': center
        })}>
            <div className={'Quote__body'}>
                {body}
            </div>
            {
                name &&
                <div className={'Quote__name'}>
                    {name}
                </div>
            }
            {
                logo &&
                <img className={'Quote__logo'} src={logo}/>
            }
        </div>
    )
}

export default Quote;
