export const buildCountdownDisplay = (countDownDate) => {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const padded = (x) => {
        return String(x).padStart(2, '0')
    }

    return `${padded(days)} : ${padded(hours)} : ${padded(minutes)} : ${padded(seconds)}`;
}