import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import classNames from "classnames";
import {noop} from "lodash";
import useMediaQuery from "../hooks/useMediaQuery";

export const Quotes = ({children, center, className}) => {
    const isMobile = useMediaQuery(768);

    return (
        <div className={classNames('Quotes', className, {
            'Quotes--center': center
        })}>
            <Carousel centerMode={true}
                      infiniteLoop={true}
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={true}
                      interval={(isMobile ? 5000 : 3000)}
                      centerSlidePercentage={100}
                      renderIndicator={(clickHandler, isSelected, index, label) => {
                          return (
                              <div className={classNames('QuoteIndicator', {
                                  'QuoteIndicator--selected': isSelected
                              })}
                                   onClick={clickHandler}>
                                  <div className={'QuoteIndicator__inner'}/>
                              </div>
                          )
                      }}
                      renderThumbs={noop}
                      renderArrowPrev={noop}
                      renderArrowNext={noop}
            >
                {children}
            </Carousel>
        </div>
    )
}

export default Quotes;