import classNames from "classnames";

export const PageBackground = ({src, inline, className}) => {
    return (
        <img src={src} className={classNames('PageBackground', className, {
            'PageBackground--inline': inline
        })}/>
    )
}

export default PageBackground;
