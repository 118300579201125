const classNames = require('classnames');

export const SectionTitle = ({title, center, weight, className}) => {
    return (
        <div className={classNames('SectionTitle', className, {
            'SectionTitle--center': center,
            [`SectionTitle--weight-${weight}`]: true
        })}>
            {title}
        </div>
    )
}

export default SectionTitle;
