import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation, Navigate} from 'react-router-dom'
import classNames from "classnames";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations";
import {Navbar} from "./components/Navbar";
import HomePage from "./pages/Home";
import EnterprisesPage from "./pages/Enterprise";
import DevelopersPage from "./pages/Developers";
import CommunityPage, {JoinTheNetworkPage} from "./pages/Community";
import WalletPage from "./pages/Wallet";
import DocsPage from "./pages/Docs";
import AboutPage from "./pages/About";
import Footer from "./components/Footer";
import EcosystemPage from "./pages/Ecosystem";
import JobPostingPage from "./pages/JobPosting";
import OfficialLinksPage from "./pages/OfficialLinks";

i18n
    .use(initReactI18next)
    .init({
      resources: translations,
      lng: "en",
      fallbackLng: "en",

      interpolation: {
        escapeValue: false
      }
    });

const withNavBar = (Component) => () => (
    <React.Fragment>
        <Navbar/>
        <Component/>
        <Footer/>
    </React.Fragment>
);

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <React.Fragment>
            <div className={classNames('App')}>
                <Router>
                    <ScrollToTop/>
                    <Routes>
                        <Route exact path="/" element={withNavBar(HomePage)()}/>
                        <Route exact path="/enterprises" element={withNavBar(EnterprisesPage)()}/>
                        <Route exact path="/developers" element={withNavBar(DevelopersPage)()}/>
                        <Route exact path="/community" element={withNavBar(CommunityPage)()}/>
                        <Route exact path="/ecosystem" element={withNavBar(EcosystemPage)()}/>
                        <Route exact path="/wallet" element={withNavBar(WalletPage)()}/>
                        <Route exact path="/docs" element={withNavBar(DocsPage)()}/>
                        <Route exact path="/about" element={withNavBar(AboutPage)()}/>
                        <Route exact path="/join" element={withNavBar(JoinTheNetworkPage)()}/>
                        <Route exact path="/official-links" element={withNavBar(OfficialLinksPage)()}/>
                        {/*<Route exact path="/jobs" element={withNavBar(JobPostingPage)()}/>*/}
                        {/* default redirect to home page */}
                        <Route path="*" element={<Navigate to="/" replace />} />

                    </Routes>
                </Router>
            </div>
        </React.Fragment>
    );
}

export default App;
