import {MediumUrl, DiscordUrl, FacebookUrl, GithubUrl, TwitterUrl} from "../constants";

import socialIconsMedium from '../assets/icons/social/medium.svg';
import socialIconsTwitter from '../assets/icons/social/twitter.svg';
import socialIconsFacebook from '../assets/icons/social/facebook.svg';
import socialIconsGithub from '../assets/icons/social/github.svg';
import socialIconsDiscord from '../assets/icons/social/discord.svg';

export const SocialIcons = () => {
    return (
        <div className={'SocialIcons'}>
            <SocialIcon icon={socialIconsMedium}
                        href={MediumUrl}
            />
            <SocialIcon icon={socialIconsTwitter}
                        href={TwitterUrl}
            />
            <SocialIcon icon={socialIconsFacebook}
                        href={FacebookUrl}
            />
            <SocialIcon icon={socialIconsGithub}
                        href={GithubUrl}
            />
            <SocialIcon icon={socialIconsDiscord}
                        href={DiscordUrl}
            />
        </div>
    );
}

export const SocialIcon = ({icon, href}) => {
    return (
        <a href={href}
           target={'_blank'}
           className={'SocialIcon'}
        >
            <img src={icon}/>
        </a>
    );
}

export default SocialIcons;