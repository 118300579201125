const classNames = require('classnames');

export const PageSection = ({id, className, children}) => {
    return (
        <div id={id}
             className={classNames('PageSection', className)}>
            {children}
        </div>
    )
}

export default PageSection;
