import ReactDOM from 'react-dom';
import Marquee from "react-fast-marquee";
import {useEffect, useState} from "react";
import useInterval from "../hooks/useInterval";

const appRoot = document.getElementById('banner-root');

export const NewsBanner = ({title, source, href}) => {
    const [play, setPlay] = useState(false);
    const [delay, setDelay] = useState(50)
    const checkIfShouldScroll = () => {
        const content = document.getElementsByClassName('NewsBanner__content')[0];
        if (content) {
            const rect = content.getBoundingClientRect();
            if ((rect.width + 100) > window.innerWidth) {
                setPlay(true);
            }

            // We got the first measurement...stop the timer
            setDelay(null);
        }
    }

    useEffect(checkIfShouldScroll, [title, source]);
    useInterval(checkIfShouldScroll, delay);

    return ReactDOM.createPortal(
        <a className={'NewsBanner'}
           target={'_blank'}
           href={href}
        >
            <Marquee gradient={false}
                     speed={60}
                     play={play}
            >
                <div className={'NewsBanner__content'}>
                    <div className={'NewsBanner__title'}>
                        {title}
                    </div>
                    <div className={'NewsBanner__source'}>
                        {` — ${source}`}
                    </div>
                </div>
            </Marquee>
        </a>,
        appRoot
    );
}

export default NewsBanner;
