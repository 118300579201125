import {useTranslation} from "react-i18next";
import {TextLinkButton} from "../components/Button";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";
import {
    DocsWhatIsThetaUrl,
    WhitepapersMainnet2Url,
    WhitepapersMainnet3Url,
    WhitepapersMainnet4Url,
    WhitepapersMultiBFTUrl,
    WhitepapersTDROPUrl
} from "../constants";

import mn4Image from '../assets/images/metachain/mn4-without-title@2x.png';
import thetaGlowImage from '../assets/images/docs/theta-glow@2x.png';

const DeveloperCard = ({title, subtitle, cta}) => {
    return (
        <div className={'DeveloperCard'}>
            <div className={'DeveloperCard__title'}>
                {title}
            </div>
            <div className={'DeveloperCard__subtitle'}>
                {subtitle}
            </div>
            {
                cta &&
                <TextLinkButton className={'DeveloperCard__cta'}
                                target={'_blank'}
                                href={cta.href}
                >
                    {cta.title}
                </TextLinkButton>
            }
        </div>
    )
};

const MainDocCard = ({image, title, subtitle, cta}) => {
    return (
        <div className={'MainDocCard'}>
            <img className={'MainDocCard__image'}
                 src={image}
            />
            <div className={'MainDocCard__title'}>
                {title}
            </div>
            <div className={'MainDocCard__subtitle'}>
                {subtitle}
            </div>
            <TextLinkButton className={'MainDocCard__link'}
                            target={'_blank'}
                            href={cta.href}
            >
                {cta.title}
            </TextLinkButton>
        </div>
    )
};


const DocsPage = () => {
    const { t } = useTranslation();

    return (
        <div className={'Page DocsPage'}>
            <PageSection>
                <HeroContent color={'purple'}
                             category={t('navbar.docs')}
                             title={t('docs.title')}
                             subtitle={t('docs.subtitle')}
                />

                <div className={'MainDocCards'}>
                    <MainDocCard title={t('docs.main-docs.what-is-theta.title')}
                                 subtitle={t('docs.main-docs.what-is-theta.subtitle')}
                                 image={thetaGlowImage}
                                 cta={{
                                     title: t('docs.main-docs.what-is-theta.cta.title'),
                                     href: DocsWhatIsThetaUrl
                                 }}
                    />
                    <MainDocCard title={t('docs.main-docs.mn4.title')}
                                 subtitle={t('docs.main-docs.mn4.subtitle')}
                                 image={mn4Image}
                                 cta={{
                                     title: t('docs.main-docs.mn4.cta.title'),
                                     href: WhitepapersMainnet4Url
                                 }}
                    />
                </div>

                <div className={'DocsPage__papers-section'}>
                    <SectionTitle title={'Library of Papers'}/>
                    <div className={'DeveloperCards'}>
                        <DeveloperCard title={t('papers.multi-bft.title')}
                                       subtitle={t('papers.multi-bft.subtitle')}
                                       cta={{
                                           title: t('papers.multi-bft.cta'),
                                           href: WhitepapersMultiBFTUrl
                                       }}
                        />
                        <DeveloperCard title={t('papers.tdrop.title')}
                                       subtitle={t('papers.tdrop.subtitle')}
                                       cta={{
                                           title: t('papers.tdrop.cta'),
                                           href: WhitepapersTDROPUrl
                                       }}
                        />
                        <DeveloperCard title={t('papers.mn3.title')}
                                       subtitle={t('papers.mn3.subtitle')}
                                       cta={{
                                           title: t('papers.mn3.cta'),
                                           href: WhitepapersMainnet3Url
                                       }}
                        />
                        <DeveloperCard title={t('papers.mn2.title')}
                                       subtitle={t('papers.mn2.subtitle')}
                                       cta={{
                                           title: t('papers.mn2.cta'),
                                           href: WhitepapersMainnet2Url
                                       }}
                        />
                    </div>
                </div>
            </PageSection>
        </div>
    )
};

export default DocsPage;