import {useTranslation} from "react-i18next";
import PageBackground from "../components/PageBackground";
import {Button, TextLinkButton} from "../components/Button";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import Quote from "../components/Quote";
import PartnerLogos from "../components/PartnerLogos";
import HeroContent from "../components/HeroContent";
import {
    DocsNFTDRMUrl,
    DocsThetaVideoAPIUrl,
    DocsValidatorUrl,
    ThetaDropUrl, ThetaEdgeCloudDocsUrl, ThetaEdgeCloudUrl,
    ThetaVideoAPIUrl
} from "../constants";

import videoPartnersBackground from '../assets/backgrounds/enterprises-video.jpg';
import mediaPartnersBackground from '../assets/backgrounds/enterprises-content.jpg';
import validatorPartnersBackground from '../assets/backgrounds/enterprises-validator.jpg';
import videoTechnologyPartnersIconsIncrementalRevenues from '../assets/images/enterprises/video-technology-partners/incremental-revenues.svg';
import videoTechnologyPartnersIconsEdgeNodes from '../assets/images/enterprises/video-technology-partners/edge-nodes@2x.png';
import videoTechnologyPartnersIconsSavings from '../assets/images/enterprises/video-technology-partners/savings@2x.png';
import mediaPartnersIconsNFTDRM from '../assets/images/enterprises/content-media-partners/nft-drm@2x.png';
import mediaPartnersIconsEngagement from '../assets/images/enterprises/content-media-partners/engagement@2x.png';
import mediaPartnersIconsPatent from '../assets/images/enterprises/content-media-partners/patent@2x.png';
import validatorPartnersIconsGlobal100 from '../assets/images/enterprises/validator-partners/global-100@2x.png';
import validatorPartnersIconsSecure from '../assets/images/enterprises/validator-partners/secure@2x.png';
import validatorPartnersIconsEarn from '../assets/images/enterprises/validator-partners/earn@2x.png';
import quoteLogosWPT from '../assets/logos/quotes/wpt@2x.png';
import quoteLogosCinedigm from '../assets/logos/quotes/cinedigm@2x.png';
import quoteLogosJukin from '../assets/logos/quotes/jukin@2x.png';
import quoteLogosAmericanIdol from '../assets/logos/quotes/american-idol@2x.png';
import quoteLogosSamsung from '../assets/logos/quotes/samsung@2x.png';
import quoteLogosPriceIsRight from '../assets/logos/quotes/price-is-right@2x.png';
import quoteLogosGoogle from '../assets/logos/quotes/google@2x.png';
import quoteLogosSony from '../assets/logos/quotes/sony@2x.png';

import vtpLogosWPT from '../assets/logos/video-technology-partners/wpt@2x.png';
import vtpLogosCinedigm from '../assets/logos/video-technology-partners/cinedigm@2x.png';
import vtpLogosGfuel from '../assets/logos/video-technology-partners/gfuel@2x.png';
import vtpLogosFailarmy from '../assets/logos/video-technology-partners/failarmy@2x.png';
import vtpLogosShoutFactory from '../assets/logos/video-technology-partners/shout-factory@2x.png';
import vtpLogosConTv from '../assets/logos/video-technology-partners/contv@2x.png';
import vtpLogosPetCollective from '../assets/logos/video-technology-partners/pet-collective@2x.png';
import vtpLogosRewardedTv from '../assets/logos/video-technology-partners/rewardedtv@2x.png';
import vtpLogosGoogle from '../assets/logos/video-technology-partners/google@2x.png';
import vtpLogosAWS from '../assets/logos/video-technology-partners/aws@2x.png';
import vtpLogosMicrosoft from '../assets/logos/video-technology-partners/microsoft@2x.png';
import vtpLogosKaist from '../assets/logos/video-technology-partners/kaist@2x.png';
import vtpLogosYonseiU from '../assets/logos/video-technology-partners/yonsei@2x.png';
import vtpLogosAethir from '../assets/logos/video-technology-partners/aethir@2x.png';

import cmpLogosLionsgate from '../assets/logos/content-and-media-partners/lionsgate@2x.png';
import cmpLogosMGM from '../assets/logos/content-and-media-partners/mgm@2x.png';
import cmpLogosNasa from '../assets/logos/content-and-media-partners/nasa@2x.png';
import cmpLogosKatyPerry from '../assets/logos/content-and-media-partners/katy-perry@2x.png';
import cmpLogosAmericanIdol from '../assets/logos/content-and-media-partners/american-idol@2x.png';
import cmpLogosSamsung from '../assets/logos/content-and-media-partners/samsung@2x.png';
import cmpLogosSony from '../assets/logos/content-and-media-partners/sony@2x.png';
import cmpLogosPriceIsRight from '../assets/logos/content-and-media-partners/price-is-right@2x.png';
import cmpLogosResortsWorld from '../assets/logos/content-and-media-partners/resorts-world@2x.png';
import cmpLogosFuse from '../assets/logos/content-and-media-partners/fuse@2x.png';
import cmpLogosGalaxyS22 from '../assets/logos/content-and-media-partners/galaxy-s22@2x.png';
import cmpLogosGalaxyZFlip from '../assets/logos/content-and-media-partners/galaxy-zflip@2x.png';
import cmpLogosSonySRD from '../assets/logos/content-and-media-partners/sony-spatial-reality-display@2x.png';

import vpLogosSamsung from '../assets/logos/validator-partners/samsung@2x.png';
import vpLogosSony from '../assets/logos/validator-partners/sony@2x.png';
import vpLogosGoogle from '../assets/logos/validator-partners/google@2x.png';
import vpLogosCAA from '../assets/logos/validator-partners/caa@2x.png';
import vpLogosBinance from '../assets/logos/validator-partners/binance@2x.png';
import vpLogosBlockchain from '../assets/logos/validator-partners/blockchain@2x.png';
import vpLogosGumi from '../assets/logos/validator-partners/gumi@2x.png';
import vpLogosReplay from '../assets/logos/validator-partners/replay@2x.png';
import vpLogosSierraVentures from '../assets/logos/validator-partners/sierra-ventures@2x.png';
import vpLogosDHVC from '../assets/logos/validator-partners/dhvc@2x.png';
import vpLogosHeuristicCapital from '../assets/logos/validator-partners/heuristic-capital@2x.png';
import vpLogosGFRFund from '../assets/logos/validator-partners/gfr-fund@2x.png';
import quoteLogosCAA from "../assets/logos/quotes/caa@2x.png";
import Quotes from "../components/Quotes";

const FeaturePoint = ({icon, title, iconHeight, iconHeightMobile}) => {
    return (
        <div className={'FeaturePoint'}>
            <div className={'FeaturePoint__icon-wrapper desktop-only'}>
                <img className={'FeaturePoint__icon'}
                     style={{height: (iconHeight || 55)}}
                     src={icon}/>
            </div>
            <div className={'FeaturePoint__icon-wrapper mobile-only'}>
                <img className={'FeaturePoint__icon'}
                     style={{height: (iconHeightMobile || 34)}}
                     src={icon}/>
            </div>
            <div className={'FeaturePoint__title'}>
                {title}
            </div>
        </div>
    )
};


const EnterprisesPage = () => {
    const { t } = useTranslation();

    return (
        <div className={'Page EnterprisesPage'}>
            <PageSection className={'VideoTechnologyPartnersSection'}
                         id={'video-technology-partners'}
            >
                <PageBackground src={videoPartnersBackground}/>
                <HeroContent color={'red'}
                             category={t('navbar.enterprises')}
                             title={t('enterprises.vtp-section.title')}
                             subtitle={t('enterprises.vtp-section.subtitle')}
                />
                <div className={'FeaturePoints'}>
                    <FeaturePoint icon={videoTechnologyPartnersIconsIncrementalRevenues}
                                  title={t('enterprises.vtp-section.point-1')}
                                  iconHeight={26}
                                  iconHeightMobile={19}
                    />
                    <FeaturePoint icon={videoTechnologyPartnersIconsEdgeNodes}
                                  title={t('enterprises.vtp-section.point-2')}
                    />
                    <FeaturePoint icon={videoTechnologyPartnersIconsSavings}
                                  title={t('enterprises.vtp-section.point-3')}
                    />
                </div>

                <div className={'EnterprisesPage__ctas'}>
                    <Button href={ThetaEdgeCloudUrl}
                            target={'_blank'}
                            size={'large'}
                            color={'green'}
                            className={'MainCTAButton'}
                    >
                        {t('enterprises.vtp-section.cta-1')}
                    </Button>
                    <TextLinkButton href={ThetaEdgeCloudDocsUrl}
                                    target={'_blank'}>
                        {t('enterprises.vtp-section.cta-2')}
                    </TextLinkButton>
                </div>

                <SectionTitle title={t('enterprises.vtp-section.logos.vtp')}/>
                <PartnerLogos logos={[
                    vtpLogosGoogle, vtpLogosAWS, vtpLogosMicrosoft, vtpLogosKaist, vtpLogosYonseiU, vtpLogosAethir,
                    vtpLogosWPT, vtpLogosCinedigm, vtpLogosFailarmy, vtpLogosShoutFactory, vtpLogosConTv,
                    vtpLogosPetCollective
                ]}/>

                <Quotes>
                    <Quote body={t('quotes.wpt.body')}
                           logo={quoteLogosWPT}
                    />
                    <Quote body={t('quotes.cinedigm.body')}
                           logo={quoteLogosCinedigm}
                    />
                    <Quote body={t('quotes.jukin.body')}
                           logo={quoteLogosJukin}
                    />
                </Quotes>
            </PageSection>


            <PageSection className={'MediaPartnersSection'}
                         id={'media-partners'}
            >
                <PageBackground src={mediaPartnersBackground}/>
                <HeroContent color={'blue'}
                             category={t('navbar.enterprises')}
                             title={t('enterprises.cmp-section.title')}
                             subtitle={t('enterprises.cmp-section.subtitle')}
                />
                <div className={'FeaturePoints'}>
                    <FeaturePoint icon={mediaPartnersIconsNFTDRM}
                                  title={t('enterprises.cmp-section.point-1')}
                    />
                    <FeaturePoint icon={mediaPartnersIconsEngagement}
                                  title={t('enterprises.cmp-section.point-2')}
                    />
                    <FeaturePoint icon={mediaPartnersIconsPatent}
                                  title={t('enterprises.cmp-section.point-3')}
                    />
                </div>

                <div className={'EnterprisesPage__ctas'}>
                    <Button href={ThetaDropUrl}
                            target={'_blank'}
                            size={'large'}
                            color={'green'}
                            className={'MainCTAButton'}
                    >
                        { t('enterprises.cmp-section.cta-1') }
                    </Button>
                    <TextLinkButton href={DocsNFTDRMUrl}
                                    target={'_blank'}
                    >
                        {t('enterprises.cmp-section.cta-2')}
                    </TextLinkButton>
                </div>

                <SectionTitle title={t('enterprises.cmp-section.logos.cmp')}/>
                <PartnerLogos logos={[
                    cmpLogosLionsgate, cmpLogosMGM, cmpLogosNasa, cmpLogosKatyPerry, cmpLogosAmericanIdol, cmpLogosSamsung, cmpLogosGalaxyS22,
                    cmpLogosSony, cmpLogosPriceIsRight, cmpLogosResortsWorld, cmpLogosFuse, cmpLogosGalaxyZFlip, cmpLogosSonySRD
                ]}/>


                <Quotes>
                    <Quote body={t('quotes.american-idol.body')}
                           logo={quoteLogosAmericanIdol}
                    />
                    <Quote body={t('quotes.samsung.body')}
                           logo={quoteLogosSamsung}
                    />
                    <Quote body={t('quotes.price-is-right.body')}
                           logo={quoteLogosPriceIsRight}
                    />
                </Quotes>
            </PageSection>

            <PageSection className={'ValidatorPartnersSection'}
                         id={'validator-partners'}
            >
                <PageBackground src={validatorPartnersBackground}/>
                <HeroContent color={'orange'}
                             category={t('navbar.enterprises')}
                             title={t('enterprises.vp-section.title')}
                             subtitle={t('enterprises.vp-section.subtitle')}
                />
                <div className={'FeaturePoints'}>
                    <FeaturePoint icon={validatorPartnersIconsGlobal100}
                                  title={t('enterprises.vp-section.point-1')}
                    />
                    <FeaturePoint icon={validatorPartnersIconsSecure}
                                  title={t('enterprises.vp-section.point-2')}
                                  iconHeight={35}
                                  iconHeightMobile={28}

                    />
                    <FeaturePoint icon={validatorPartnersIconsEarn}
                                  title={t('enterprises.vp-section.point-3')}
                                  iconHeight={44}
                                  iconHeightMobile={34}
                    />
                </div>

                <div className={'EnterprisesPage__ctas'}>
                    <Button href={DocsValidatorUrl}
                            target={'_blank'}
                            size={'large'}
                            color={'green'}
                            className={'MainCTAButton'}
                    >
                        {t('enterprises.vp-section.cta-1')}
                    </Button>
                </div>

                <SectionTitle title={t('enterprises.vp-section.logos.mt')}/>
                <PartnerLogos logos={[
                    vpLogosSamsung, vpLogosSony, vpLogosGoogle, vpLogosCAA
                ]}/>

                <SectionTitle title={t('enterprises.vp-section.logos.bc')}/>
                <PartnerLogos logos={[
                    vpLogosBinance, vpLogosBlockchain, vpLogosGumi, vpLogosReplay
                ]}/>

                <SectionTitle title={t('enterprises.vp-section.logos.ii')}/>
                <PartnerLogos logos={[
                    vpLogosSierraVentures, vpLogosDHVC, vpLogosHeuristicCapital, vpLogosGFRFund
                ]}/>

                <Quotes>
                    <Quote body={t('quotes.google.body')}
                           logo={quoteLogosGoogle}
                    />
                    <Quote body={t('quotes.sony.body')}
                           logo={quoteLogosSony}
                    />
                </Quotes>
            </PageSection>
        </div>
    )
};

export default EnterprisesPage;