const projects = [
    {
        title: 'Theta Wallet',
        subtitle: 'The Theta Wallet allows you to securely manage and store your Theta tokens.',
        image: 'theta-wallet@2x.png',
        href: 'https://wallet.thetatoken.org',
        tags: ['wallets']
    },
    {
        title: 'ChainSmith',
        subtitle: 'ChainSmith is a comprehensive management tool for the streamlined creation and deployment of subchains on the Theta Network. It empowers users to create, manage, and maintain a subchain without requiring comprehensive knowledge of the underlying infrastructure.',
        image: 'chainsmith@2x.png',
        href: 'https://github.com/fuelfoundry/chainsmith',
        tags: ['tools']
    },
    {
        title: 'Atomic Wallet',
        subtitle: 'Atomic Wallet is a decentralized Cryptocurrency wallet that supports more than 500 coins and tokens, providing simplicity, safety, and convenience for its users.',
        image: 'atomic-wallet@2x.png',
        href: 'https://atomicwallet.io/',
        tags: ['wallets']
    },
    {
        title: 'Metamask',
        subtitle: 'MetaMask is a software cryptocurrency wallet used to interact with the Ethereum blockchain.',
        image: 'metamask@2x.png',
        href: 'https://metamask.io/',
        tags: ['wallets']
    },
    // {
    //     title: 'FedML',
    //     subtitle: 'The Machine Learning platform to train, deploy, monitor, and improve models collaboratively.',
    //     image: 'fedml@2x.png',
    //     href: 'https://www.fedml.ai/',
    //     tags: ['ai/ml']
    // },
    {
        title: 'Lavita',
        subtitle: 'The first private health data marketplace powered by AI, blockchain & YOU.',
        image: 'lavita@2x.png',
        href: 'https://www.lavita.ai/',
        tags: ['ai/ml']
    },
    {
        title: 'POG Digital',
        subtitle: 'An interoperable universal gaming ecosystem, officially created by THE WORLD POG FEDERATION™, the wildly popular 90\'s game and collectibles brand.',
        image: 'pog-digital@2x.png',
        href: 'https://www.pogdigital.com/',
        tags: ['nfts']
    },
    {
        title: 'Trust Wallet',
        subtitle: 'Trust Wallet is cryptocurrency wallet software that allows you to trade various cryptocurrencies safely.',
        image: 'trust-wallet@2x.png',
        href: 'https://atomicwallet.io/',
        tags: ['wallets']
    },
    {
        title: 'Exodus',
        subtitle: 'Exodus is a cryptocurrency wallet with an incorporated, decentralized cryptocurrency exchange. It offers 138 cryptocurrencies for crypto-to-crypto, peer-to-peer swaps.',
        image: 'exodus@2x.png',
        href: 'https://www.exodus.com/',
        tags: ['wallets']
    },
    {
        title: 'Voltswap',
        subtitle: 'VoltSwap is the first major DEX in the Meter ecosystem. It is a completely community-driven project and was introduced by the Meter.io team to showcase the capabilities of the Meter blockchain.',
        image: 'volt-swap@2x.png',
        href: 'https://info.voltswap.finance/#/home?network=theta/',
        tags: ['defi']
    },
    {
        title: 'ThetaDrop',
        subtitle: 'ThetaDrop is a next-generation non-fungible token (NFT) marketplace for digital collectibles',
        image: 'theta-drop@2x.png',
        href: 'https://www.thetadrop.com',
        tags: ['nfts']
    },
    {
        title: 'OpenTheta',
        subtitle: 'OpenTheta is the first independent marketplace for non-fungible tokens on the Theta blockchain.',
        image: 'opentheta@2x.png',
        href: 'https://www.opentheta.io',
        tags: ['nfts']
    },
    {
        title: 'Cyko KO',
        subtitle: 'A Ringo-Award nominated comic book creator, animation producer and Theta TV streamer.',
        image: 'cyko-ko@2x.png',
        href: 'https://www.cykoko.com/',
        tags: ['nfts']
    },
    // {
    //     title: 'Metapass',
    //     subtitle: 'Metapass allows you to create events on Theta and sell NFT tickets so you can token gate your event and also server proof of attendance.',
    //     image: 'metapass@2x.png',
    //     href: 'https://www.metapass.world/',
    //     tags: ['nfts', 'tools']
    // },
    // {
    //     title: 'ThetaLands',
    //     subtitle: 'Metaverse built on Theta',
    //     image: 'theta-lands@2x.png',
    //     href: 'https://my.thetalands.io/',
    //     tags: ['games', 'nfts']
    // },
    {
        title: 'Replay',
        subtitle: 'A Video Tracking & Payments Platform powered by Theta Blockchain Technology',
        image: 'replay@2x.png',
        href: 'https://imaginereplay.com//',
        tags: ['video']
    },
    {
        title: 'Theta.tv',
        subtitle: 'THETA.tv is the next-generation esports entertainment platform powered by blockchain technology.',
        image: 'thetatv@2x.png',
        href: 'https://www.theta.tv',
        tags: ['video']
    },
    {
        title: 'Rewarded TV',
        subtitle: 'A blockchain-enabled OTT streaming service enabling viewers to earn crypto in real-time for watching, sharing and engaging with their favorite content, without ads or subscriptions.',
        image: 'rewardedtv@2x.png',
        href: 'https://www.rewarded.tv/',
        tags: ['video']
    },
    {
        title: 'ThetaScan',
        subtitle: 'ThetaScan.io is a full node block explorer for the Theta Network. It allows the user to search for addresses and transaction hashes.',
        image: 'theta-scan@2x.png',
        href: 'https://www.thetascan.io/',
        tags: ['tools']
    },
    {
        title: 'Thetaboard',
        subtitle: 'Thetaboard\'s mission is to become the central place for any Theta enthusiast.',
        image: 'thetaboard@2x.png',
        href: 'https://www.thetaboard.io/',
        tags: ['tools']
    },
    {
        title: 'Guardian Monitor',
        subtitle: 'Designed to aid Guardian Node operators the tools they need to be successful.',
        image: 'guardian-monitor@2x.png',
        href: 'https://guardianmonitor.io/',
        tags: ['tools']
    },
    // {
    //     title: 'GPool',
    //     subtitle: 'Built by veterans of the crypto currency space and is based on a community-inspired vision to provide secure pooling services across numerous digital assets.',
    //     image: 'gpool@2x.png',
    //     href: 'https://gpool.io/',
    //     tags: ['tools']
    // },
    {
        title: 'ThetaDropRarity',
        subtitle: 'Helps guide the Theta community in making informed and optimal NFT choices.',
        image: 'theta-drop-rarity@2x.png',
        href: 'https://www.thetadroprarity.com/',
        tags: ['tools', 'nfts']
    },
    {
        title: 'Secret Pineapple Society / The Grove',
        subtitle: 'The Secret Pineapple is a source of mystery and power spanning millennia. Only a select few have been able to harness their power. These individuals have gathered to create The Society.',
        image: 'sps@2x.png',
        href: 'https://www.secretpineapplesociety.com/',
        tags: ['nfts']
    },
    {
        title: 'Passaways',
        subtitle: 'The Passaways are a collection of 9666 generative souls captured on film. Owning a Passaway grants you exclusive access into the Misadventure Club.',
        image: 'passaways@2x.png',
        href: 'https://www.passaways.com/',
        tags: ['nfts']
    },
    {
        title: 'Order of the Tigons',
        subtitle: 'The Order of the Tigons is a collection of 4,577 unique digital art collectibles inspired by the talented creator and actor, Jon Heder.',
        image: 'tigons@2x.png',
        href: 'https://www.orderofthetigons.com/',
        tags: ['nfts']
    },
    {
        title: 'Mystic Gurus',
        subtitle: 'The Great Guru of Yonder - Like all Spectral Gurus of Yesteryear - is a shape-shifting entity, and he chooses his form after a Seeker has read the Golden Words from the Mystic Vessel.',
        image: 'mystic-gurus@2x.png',
        href: 'https://www.mysticgurus.club/',
        tags: ['nfts']
    },
    {
        title: 'Imaginary Friends Co',
        subtitle: 'We\'re Imaginary Friends Co. We formed to help the Imaginary Friends get out of their Imaginary Dimension and onto the Theta blockchain. Sadly, their world has been overrun by Imaginary Enemies.',
        image: 'ifc@2x.png',
        href: 'https://www.imaginaryfriends.co/',
        tags: ['nfts']
    },
    {
        title: 'ThetaMillion.com',
        subtitle: 'The contract implements the NFT (Non-Fungible Token) standard. That means every bought spot is also an NFT. By buying a spot you are actually creating/minting an NFT.',
        image: 'theta-million@2x.png',
        href: 'https://www.thetamillion.com/',
        tags: ['nfts']
    },
    {
        title: 'ThetaPugs',
        subtitle: 'ThetaPugs is a randomly generated collection of 2,500 Pug NFT’s exclusive to the Theta Network. Each Pug randomly selects a combination of clothing, headwear and accessories./minting an NFT.',
        image: 'theta-pugs@2x.png',
        href: 'https://thetapugs.com/',
        tags: ['nfts']
    },
    {
        title: 'Battle Pugs',
        subtitle: 'Battle Pugs are a fierce warrior race which once roamed the land and now they’ve returned! Owning any of these Battle Pugs grants you special utility.',
        image: 'battle-pugs@2x.png',
        href: 'https://battlepugs.com/',
        tags: ['nfts']
    },
    {
        title: 'Theta Frogs',
        subtitle: 'Theta Frogs are NFTs on THETA Drop! Collectible, tradeable, hand-drawn frogs based on your favorite pop culture icons, historical figures and childhood heroes!\n',
        image: 'theta-frogs@2x.png',
        href: 'https://twitter.com/THETAFROGS',
        tags: ['nfts']
    },
    // {
    //     title: 'Curate',
    //     subtitle: 'The Gasless NFT & Physical Goods Marketplace.',
    //     image: 'curate@2x.png',
    //     href: 'https://curate.style/',
    //     tags: ['nfts']
    // },
    // {
    //     title: 'Theta Video Plugin',
    //     subtitle: 'The Theta Video Plugin for WordPress is a plugin which allows WordPress users to easily use the Theta Video API.',
    //     image: 'theta-video-plugin@2x.png',
    //     href: 'https://thetavideoplugin.com/',
    //     tags: ['tools']
    // },
    {
        title: 'WPT',
        subtitle: 'The World Poker Tour is an internationally televised gaming and entertainment brand.',
        image: 'wpt-shows@2x.png',
        href: 'https://www.worldpokertour.com/',
        tags: ['video', 'nfts']
    },
    // {
    //     title: 'Sizzle',
    //     subtitle: 'The Devil\'s Favorite Popcorn focused on empowering creators in Gaming, Streaming & Entertainment.',
    //     image: 'sizzle@2x.png',
    //     href: 'https://sizzlepopcorn.com/pages/entertainment',
    //     tags: ['video']
    // },
    {
        title: 'God Mode Unlocked',
        subtitle: 'A weekly podcast focused on gaming news on the interweb.',
        image: 'god-mod-unlocked@2x.png',
        href: 'https://www.godmodeunlocked.net/',
        tags: ['video']
    },
    {
        title: 'ThetaSwap',
        subtitle: 'ThetaSwap is the first decentralized exchange built on Theta blockchain, using an open-source smart contract system built based on Automated Market Maker logic similar to that of UniSwap.',
        image: 'theta-swap@2x.png',
        href: 'https://swap.thetatoken.org/swap',
        tags: ['defi']
    },
    {
        title: 'Theta Name Service',
        subtitle: 'A suite of smart contracts to enable registration, assigning to address, and transfer of .theta domain names hosted on the Theta blockchain.',
        image: 'tns@2x.png',
        href: 'https://thetaboard.io/domain/search',
        tags: ['tools']
    },
    {
        title: 'Meter Passport',
        subtitle: 'Meter Passport is an N way blockchain router that allows both assets and smart contracts to travel across heterogeneous blockchain networks.',
        image: 'meter-passport@2x.png',
        href: 'https://passport.meter.io/#/',
        tags: ['defi', 'tools']
    },
    // {
    //     title: 'Theta Bridge',
    //     subtitle: 'Cross chain bridge built on Theta',
    //     image: 'theta-bridge@2x.png',
    //     href: 'https://thetabridge.io/bridge',
    //     tags: ['defi', 'tools']
    // },
    {
        title: 'Theta Data',
        subtitle: 'Retrieve all the data you need from Theta Network with one single Graphql statement.',
        image: 'theta-data@2x.png',
        href: 'https://www.thetadata.io/',
        tags: ['tools']
    },
    {
        title: 'Bullit',
        subtitle: 'Bullit is a new service that eradicates the need for Secure File Transfer, Secure Email and Secure Data Storage, consolidating them into one single easy-to-use app.',
        image: 'bullit@2x.png',
        href: 'https://bullit.app/',
        tags: ['tools']
    },
    {
        title: 'ThetaCon',
        subtitle: 'A collective community-created and hosted event in support of the Theta Blockchain Ecosystem',
        image: 'thetacon@2x.png',
        href: 'https://thetacon.org/',
        tags: ['events']
    },
    {
        title: 'ThetaZillaClub',
        subtitle: 'ThetaZillaClub is a series of unique NFT collections designed to embrace the power of Theta Blockchain Technology using visual imagery and music.',
        image: 'thetazillaclub@2x.png',
        href: 'https://thetazillaclub.com/',
        tags: ['nfts']
    },
    {
        title: 'Clan Wars TCG',
        subtitle: 'Theta NFT-based trading card game',
        image: 'clanwars@2x.png',
        href: 'https://playclanwars.thetararity.com/',
        tags: ['games','nfts']
    },
    {
        title: 'Fuse Wars',
        subtitle: 'Third person shooter multiplayer experience developed by Symbiote Creatives',
        image: 'fusewars@2x.png',
        href: 'https://symbiotecreatives.com/',
        tags: ['games','nfts']
    },
    // {
    //     title: 'IEL',
    //     subtitle: 'Animation and Web3 Entertainment studio',
    //     image: 'iel@2x.png',
    //     href: 'https://www.ielmetaverse.com/',
    //     tags: ['games','nfts']
    // },
    {
        title: 'World of Women',
        subtitle: 'Inclusive NFT collection and Web3 community',
        image: 'wow@2x.png',
        href: 'https://www.worldofwomen.art/',
        tags: ['nfts', 'video']
    },
    {
        title: 'TrooVRS',
        subtitle: 'Immersive media platform for content across virtual editorial environments, experiences, worlds, NFTs, and eCommerce.',
        image: 'troovrs@2x.png',
        href: 'https://troovrs.io/',
        tags: ['nfts']
    },
    {
        title: 'FuelFoundry',
        subtitle: 'Web5 as a Service Decentralized on Theta',
        image: 'fuelfoundry@2x.png',
        href: 'https://www.fuelfoundry.io/',
        tags: ['tools']
    }
]

export default projects;