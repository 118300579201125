import classNames from "classnames";

export const Tag = ({name, active, className, onClick}) => {
    return (
        <div className={ classNames('Tag', className, {
            'Tag--active': active
        })}
        onClick={() => {
            onClick(name)
        }}>
            {name}
        </div>
    )
}

export default Tag;
