import _ from 'lodash';
const classNames = require('classnames');

export const PartnerLogos = ({className, logos}) => {
    return (
        <div className={classNames('PartnerLogos', className)}>
            {
                _.map(logos, (logo) => {
                    return (
                        <img className={'PartnerLogo'}
                             src={logo}
                        />
                    );
                })
            }
        </div>
    )
}

export default PartnerLogos;
