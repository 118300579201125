import classNames from "classnames";

export const PersonCard = ({headshot, name, title, logo, className}) => {
    return (
        <div className={ classNames('PersonCard', className) }>
            <img className={'PersonCard__headshot'}
                 src={headshot}/>

            <div className={'PersonCard__name'}>
                {name}
            </div>
            <div className={'PersonCard__title'}>
                {title}
            </div>
            {
                logo &&
                <div className={'PersonCard__logo-wrapper'}>
                    <img className={'PersonCard__logo'} src={logo}/>
                </div>
            }
        </div>
    )
}

export default PersonCard;
