import {NavLink} from "react-router-dom";
import logo from "../assets/logos/theta.svg";
import SocialIcons from "./SocialIcons";
import {HeaderLink} from "./Navbar";
import {useTranslation} from "react-i18next";
import externalLinkIcon from "../assets/icons/navbar/external-link@2x.png";

const Footer = ({}) => {
    const { t } = useTranslation();

    return (
        <div className={'Footer'}>
            <div className={'Footer__content'}>
                <div className={'Footer__col1'}>
                    <NavLink to={'/'}
                             className={'Footer__logo-wrapper'}>
                        <img src={logo}
                             className={'Footer__logo'}
                        />
                    </NavLink>

                    <SocialIcons/>

                    <div className={'Footer__copyright'}>
                        ©2023 Theta Labs, Inc. All rights reserved.
                    </div>
                </div>
                <div className={'Footer__col2'}>
                    <>
                        <HeaderLink to={'/enterprises'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.enterprises')}
                        </HeaderLink>
                        <HeaderLink to={'/developers'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.developers')}
                        </HeaderLink>
                        <HeaderLink to={'/community'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.community')}
                        </HeaderLink>
                        <HeaderLink to={'https://explorer.thetatoken.org'}
                                    target='_blank'>
                            <span>{t('navbar.explorer')}</span>
                            <img src={externalLinkIcon}
                                 className={'NavbarLink__external-link'}
                            />
                        </HeaderLink>
                        <HeaderLink to={'/wallet'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.wallet')}
                        </HeaderLink>
                        <HeaderLink to={'/about'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.about')}
                        </HeaderLink>
                        <HeaderLink to={'/community#product-roadmap'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.product-roadmap')}
                        </HeaderLink>
                        <HeaderLink to={'/docs'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.docs')}
                        </HeaderLink>
                        <HeaderLink to={'https://support.thetanetwork.org/hc/en-us'}
                                    className={'FooterLink'}
                                    target='_blank'
                        >
                            {t('navbar.support')}
                        </HeaderLink>
                        <HeaderLink to={'/official-links'}
                                    className={'FooterLink'}
                        >
                            {t('navbar.official-links')}
                        </HeaderLink>
                        {/*<HeaderLink to={'/jobs'}*/}
                        {/*            className={'FooterLink'}*/}
                        {/*            target='_blank'*/}
                        {/*>*/}
                        {/*    Jobs*/}
                        {/*</HeaderLink>*/}
                    </>
                </div>
                <div className={'Footer__col3'}>
                    <>
                        <HeaderLink to={'https://www.thetadrop.com'}
                                    target={'_blank'}
                                    className={'FooterLink'}
                        >
                            ThetaDrop
                        </HeaderLink>
                        <HeaderLink to={'https://www.thetavideoapi.com'}
                                    target={'_blank'}
                                    className={'FooterLink'}
                        >
                            Theta Video API
                        </HeaderLink>
                        <HeaderLink to={'https://docs.thetatoken.org'}
                                    target={'_blank'}
                                    className={'FooterLink'}
                        >
                            Theta Docs
                        </HeaderLink>

                    </>
                </div>
            </div>
        </div>
    )
};

export default Footer;