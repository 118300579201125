import {useTranslation} from "react-i18next";
import PageBackground from "../components/PageBackground";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";

import walletBackground from '../assets/backgrounds/wallet.jpg';

import wPlatformIconWeb from '../assets/icons/wallet/web@2x.png';
import wPlatformIconIOS from '../assets/icons/wallet/ios@2x.png';
import wPlatformIconAndroid from '../assets/icons/wallet/android@2x.png';
import wPlatformIconChrome from '../assets/icons/wallet/chrome@2x.png';
import wPlatformIconMetamask from '../assets/icons/wallet/metamask@2x.png';
import {WalletsAndroidUrl, WalletsChromeUrl, WalletsIOSUrl, WalletsMetamaskUrl, WalletsWebUrl} from "../constants";

const WalletCard = ({name, icon, href}) => {
    return (
        <a className={'WalletCard'}
           href={href}
           target={'_blank'}
        >
            <div className={'WalletCard__icon-wrapper'}>
                <img className={'WalletCard__icon'}
                     src={icon}
                />
            </div>
            <div className={'WalletCard__name'}>
                {name}
            </div>
        </a>
    )
}

const WalletPage = () => {
    const {t} = useTranslation();

    return (
        <div className={'Page WalletPage'}>
            <PageSection>
                <PageBackground src={walletBackground}/>
                <HeroContent color={'pink'}
                             category={t('navbar.wallet')}
                             title={t('wallet.title')}
                             subtitle={t('wallet.subtitle')}
                />

                <div className={'WalletPage__platforms-section'}>
                    <SectionTitle title={t('wallet.platforms-section.title')}/>

                    <div className={'WalletCards'}>
                        <WalletCard name={'Web Wallet'}
                                    icon={wPlatformIconWeb}
                                    href={WalletsWebUrl}
                        />
                        <WalletCard name={'iOS Wallet'}
                                    icon={wPlatformIconIOS}
                                    href={WalletsIOSUrl}
                        />
                        <WalletCard name={'Android Wallet'}
                                    icon={wPlatformIconAndroid}
                                    href={WalletsAndroidUrl}
                        />
                        {/*<WalletCard name={'Chrome Extension'}*/}
                        {/*            icon={wPlatformIconChrome}*/}
                        {/*            href={WalletsChromeUrl}*/}
                        {/*/>*/}
                        <WalletCard name={'Metamask'}
                                    icon={wPlatformIconMetamask}
                                    href={WalletsMetamaskUrl}
                        />
                    </div>
                </div>
            </PageSection>

        </div>
    )
};

export default WalletPage;