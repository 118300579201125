import _ from 'lodash';
import {NavHashLink} from "react-router-hash-link";

const classNames = require('classnames');

const NavLink = NavHashLink;

export const TextLinkButton = ({children, href, target, onClick, className, style}) => {
    if(target === '_blank' || _.isEmpty(href)){
        return (
            <a className={classNames('TextLinkButton', className)}
               style={style}
               onClick={onClick}
               href={href}
               target={target}
            >
                {children}
            </a>
        )
    }
    else{
        return (
            <NavLink className={classNames('TextLinkButton', className)}
                     style={style}
                     to={href}
                     exact
            >
                {children}
            </NavLink>
        )
    }
}

export const Button = ({children, size, color, href, target, onClick, className, style}) => {
    return (
        <a className={classNames('Button', className, {
            [`Button--${color}`]: true,
            [`Button--${size}`]: true,
        })}
           style={style}
           onClick={onClick}
           href={href}
           target={target}
        >
            {children}
        </a>
    )
}


export const DownloadButton = ({title, icon, href, target, onClick, className, style}) => {
    return (
        <a className={classNames('DownloadButton', className)}
           style={style}
           onClick={onClick}
           href={href}
           target={target || '_blank'}
        >
            <div className={'DownloadButton__icon-wrapper'}>
                <img src={icon}/>
            </div>
            <span>{title}</span>
        </a>
    )
}