import _ from 'lodash';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {TextLinkButton} from "../components/Button";
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";
import Tag from "../components/Tag";
import projects from "../projects";

const ProjectCard = ({image, title, subtitle, href}) => {
    const { t } = useTranslation();

    return (
        <div className={'ProjectCard'}>
            <img src={image}
                 className={'ProjectCard__image'}
            />
            <div className={'ProjectCard__title'}>
                {title}
            </div>
            <div className={'ProjectCard__subtitle'}>
                {subtitle}
            </div>
            <TextLinkButton className={'ProjectCard__cta'}
                            target={'_blank'}
                            href={href}
            >
                {t('common.view-project')}
            </TextLinkButton>
        </div>
    )
};

const Tags = ['defi', 'games', 'nfts', 'tools', 'video', 'wallets', 'events', 'ai/ml'];

const EcosystemPage = () => {
    const { t } = useTranslation();
    const [activeTags, setActiveTags] = useState(new Set([]));
    const onTagClick = (tag) => {
        if(activeTags.has(tag)){
            activeTags.delete(tag);
        }
        else{
            activeTags.add(tag);
        }

        setActiveTags(new Set(activeTags.values()));
    }

    return (
        <div className={'Page EcosystemPage'}>
            <PageSection>
                <HeroContent color={'purple'}
                             category={t('navbar.ecosystem')}
                             title={t('ecosystem.title')}
                             subtitle={t('ecosystem.subtitle')}
                />

                <div className={'EcosystemPage__projects-section'}>
                    <div className={'Tags'}>
                        {
                            _.map(Tags, (tag) => {
                                return (
                                    <Tag name={tag}
                                         active={activeTags.has(tag)}
                                         onClick={onTagClick}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className={'ProjectCards'}>
                        {
                            _.map(projects, ({image, title, subtitle, href, tags}) => {
                                if(activeTags.size === 0 || activeTags.has(tags[0]) || activeTags.has(tags[1])){
                                    return (
                                        <ProjectCard
                                            image={`/images/ecosystem/${image}`}
                                            title={title}
                                            subtitle={subtitle}
                                            href={href}
                                        />
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </PageSection>
        </div>
    )
};

export default EcosystemPage;