import {TextLinkButton} from "./Button";
import {useTranslation} from "react-i18next";

export const NewsArticle = ({title, source, href, date}) => {
    const { t } = useTranslation();

    return (
        <div className={'NewsArticle'}>
            <div className={'NewsArticle__source'}>{source}</div>
            <div className={'NewsArticle__date'}>{new Date(date).toLocaleDateString()}</div>
            <div className={'NewsArticle__title'}>{title}</div>
            <TextLinkButton target={'_blank'}
                            href={href}>
                {t('common.view-article')}
            </TextLinkButton>
        </div>
    )
}

export default NewsArticle;
