import React, {useEffect, useState} from "react";
import {NavHashLink} from 'react-router-hash-link';
import { useTranslation, initReactI18next } from "react-i18next";
import {push as Menu} from 'react-burger-menu';
import classNames from "classnames";
import SocialIcons from "./SocialIcons";
import useMediaQuery from "../hooks/useMediaQuery";

import logo from '../assets/logos/theta.svg';
import hamburgerIcon from '../assets/icons/navbar/hamburger@2x.png';
import hamburgerXIcon from '../assets/icons/navbar/x@2x.png';
import externalLinkIcon from '../assets/icons/navbar/external-link@2x.png';

const NavLink = NavHashLink;

export const HeaderLink = ({to, target, children, className, onClick}) => {
    if(target){
        return (
            <a href={to}
               target={target}
               className={classNames("NavbarLink", className)}
               onClick={onClick}
            >
                {children}
            </a>
        );
    }
    return (
        <NavLink to={to}
                 target={target}
                 className={({ isActive }) => classNames("NavbarLink", {
                     'NavbarLink--active': isActive,
                     [className]: true
                 })}
                 onClick={onClick}
                 exact
        >
            {children}
            <div className={'NavbarLink__border'}/>
        </NavLink>
    );
}

export const Navbar = ({}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(768);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const onOpenClick = () => {
        setIsMenuOpen(true);
    };
    const onCloseClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const onMenuStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    };
    const links = (
      <>
          <HeaderLink to={'/enterprises'}
                      onClick={onCloseClick}
          >
              {t('navbar.enterprises')}
          </HeaderLink>
          {
              isMobile &&
              <HeaderLink to={'/enterprises#video-technology-partners'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.video-technology-partners')}
              </HeaderLink>
          }
          {
              isMobile &&
              <HeaderLink to={'/enterprises#media-partners'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.content-and-media-partners')}
              </HeaderLink>
          }
          {
              isMobile &&
              <HeaderLink to={'/enterprises#validator-partners'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.validator-partners')}
              </HeaderLink>
          }
          <HeaderLink to={'/developers'}
                      onClick={onCloseClick}
          >
              {t('navbar.developers')}
          </HeaderLink>
          {
              isMobile &&
              <HeaderLink to={'/developers#resources'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.resources')}
              </HeaderLink>
          }
          {
              isMobile &&
              <HeaderLink to={'/developers#metachain-overview'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.metachain-overview')}
              </HeaderLink>
          }
          {
              isMobile &&
              <HeaderLink to={'/ecosystem'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.ecosystem')}
              </HeaderLink>
          }
          <HeaderLink to={'/community'}
                      onClick={onCloseClick}
          >
              {t('navbar.community')}
          </HeaderLink>
          {
              isMobile &&
              <HeaderLink to={'/community#theta-edge-network'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.theta-edge-network')}
              </HeaderLink>
          }
          {
              isMobile &&
              <HeaderLink to={'/community#earn-and-stake'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.earn-stake-trade')}
              </HeaderLink>
          }
          {
              isMobile &&
              <HeaderLink to={'/community#product-roadmap'}
                          className={'NavbarLink--SubLink'}
                          onClick={onCloseClick}
              >
                  {t('navbar.product-roadmap')}
              </HeaderLink>
          }
          <HeaderLink to={'https://explorer.thetatoken.org'}
                      target='_blank'
                      onClick={onCloseClick}
                      className={'NavbarLink--explorer'}
          >
              <span>{t('navbar.explorer')}</span>
              <img src={externalLinkIcon}
                   className={'NavbarLink__external-link'}
              />
          </HeaderLink>
          <HeaderLink to={'/wallet'}
                      onClick={onCloseClick}
          >
              {t('navbar.wallet')}
          </HeaderLink>
          <HeaderLink to={'/about'}
                      onClick={onCloseClick}
          >
              {t('navbar.about')}
          </HeaderLink>
          <HeaderLink to={'/docs'}
                      onClick={onCloseClick}
          >
              {t('navbar.docs')}
          </HeaderLink>
      </>
    );

    return (
        <div className={'Navbar'}>
            {
                isMobile &&
                <React.Fragment>
                    <a onClick={onOpenClick}
                       className={'NavbarHamburgerButton'}
                    >
                        <img src={hamburgerIcon}/>
                    </a>
                    <div className={'Navbar__content'}>
                        <NavLink to={'/'}
                                 className={'Navbar__logo-wrapper'}>
                            <img src={logo}
                                 className={'Navbar__logo mobile-only'}
                            />
                        </NavLink>
                    </div>
                    <Menu isOpen={isMenuOpen}
                          disableAutoFocus
                          itemListElement='div'
                          customBurgerIcon={false}
                          customCrossIcon={false}
                          onStateChange={onMenuStateChange}>
                        <div className={'NavBarMenuHeader'}>
                            <a onClick={onCloseClick}
                               className={'NavBarMenuHeader__close'}>
                                <img src={hamburgerXIcon}/>
                            </a>
                            {links}
                        </div>
                        <SocialIcons/>
                    </Menu>
                </React.Fragment>
            }
            {
                !isMobile &&
                <div className={'Navbar__content'}>
                    <div className={'Navbar__links'}>
                        <NavLink to={'/'}>
                            <img src={logo}
                                 className={'Navbar__logo'}
                            />
                        </NavLink>
                        {links}
                    </div>
                    <SocialIcons/>
                </div>
            }

        </div>
    )
}
