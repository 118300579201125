import _ from "lodash";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import PageBackground from "../components/PageBackground";
import {Button, DownloadButton, TextLinkButton} from "../components/Button";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";
import {
    DiscordUrl,
    DocsEdgeNodeUrl,
    DocsEliteEdgeNodeUrl,
    DocsGuardianNodeUrl, DocsHomeUrl,
    DocsTDROPUrl, DocsWhatIsThetaUrl,
    DownloadsENLinuxUrl,
    DownloadsENMacOSUrl,
    DownloadsENWindowsUrl,
    DownloadsGNCLIUrl,
    DownloadsGNLinuxUrl, DownloadsGNMacOSUrl, DownloadsGNWindowsUrl,
    ExchangesBinanceUrl,
    ExchangesBinanceUSUrl, ExchangesBitfinexUrl,
    ExchangesBithumbUrl,
    ExchangesCryptoComUrl,
    ExchangesGateUrl,
    ExchangesHuobiUrl, ExchangesIndodaxUrl, ExchangesKorbitUrl, ExchangesKucoinUrl,
    ExchangesOKXUrl,
    ExchangesThetaSwapUrl, ExchangesTokoUrl,
    ExchangesUpbitUrl,
    ExchangesVoltSwapUrl,
    FacebookUrl,
    GithubUrl,
    MediumUrl,
    TwitterUrl,
    WalletsAndroidUrl,
    WalletsIOSUrl,
    WalletsWebUrl
} from "../constants";

import communityBackground from '../assets/backgrounds/community.jpg';

import sLogosMedium from '../assets/logos/social/medium@2x.png';
import sLogosTwitter from '../assets/logos/social/twitter@2x.png';
import sLogosDiscord from '../assets/logos/social/discord@2x.png';
import sLogosFacebook from '../assets/logos/social/facebook@2x.png';
import sLogosGithub from '../assets/logos/social/github@2x.png';

import eLogosBinance from '../assets/logos/exchanges/binance@2x.png';
import eLogosBinanceUS from '../assets/logos/exchanges/binance-us@2x.png';
import eLogosHuobi from '../assets/logos/exchanges/huobi@2x.png';
import eLogosOKX from '../assets/logos/exchanges/okx@2x.png';
import eLogosGate from '../assets/logos/exchanges/gate@2x.png';
import eLogosBithumb from '../assets/logos/exchanges/bithumb@2x.png';
import eLogosUpbit from '../assets/logos/exchanges/upbit@2x.png';
import eLogosCryptoCom from '../assets/logos/exchanges/crypto-com@2x.png';
import eLogosThetaSwap from '../assets/logos/exchanges/thetaswap@2x.png';
import eLogosVoltSwap from '../assets/logos/exchanges/voltswap@2x.png';
import eLogosKorbit from '../assets/logos/exchanges/korbit@2x.png';
import eLogosKucoin from '../assets/logos/exchanges/kucoin@2x.png';
import eLogosBitfinex from '../assets/logos/exchanges/bitfinex@2x.png';
import eLogosToko from '../assets/logos/exchanges/toko@2x.png';
import eLogosIndodax from '../assets/logos/exchanges/indodax@2x.png';

import eIconsEN from '../assets/icons/earn/en@2x.png';
import eIconsGN from '../assets/icons/earn/gn@2x.png';
import eIconsEEN from '../assets/icons/earn/een@2x.png';
import eIconsTDROP from '../assets/icons/earn/tdrop@2x.png';

import pIconsWindows from '../assets/icons/platforms/windows@2x.png';
import pIconsApple from '../assets/icons/platforms/apple@2x.png';
import pIconsAndroid from '../assets/icons/platforms/android@2x.png';
import pIconsLinux from '../assets/icons/platforms/linux@2x.png';
import pIconsCLI from '../assets/icons/platforms/cli@2x.png';
import pIconsWeb from '../assets/icons/platforms/web@2x.png';

import rmArrowIconRed from '../assets/icons/roadmap/arrow-red.svg';
import rmArrowIconBlue from '../assets/icons/roadmap/arrow-blue.svg';
import rmArrowIconGreen from '../assets/icons/roadmap/arrow-green.svg';
import rmArrowIconYellow from '../assets/icons/roadmap/arrow-yellow.svg';


const EarningCard = ({kind, icon, title, subtitle, cta, href, children}) => {
    return (
        <div className={'EarnCard'}>
            <div className={'EarnCard__body'}>
                <div className={'EarnCard__kind'}>
                    {kind}
                </div>
                <img className={'EarnCard__icon'}
                     src={icon}
                />
                <div className={'EarnCard__title'}>
                    {title}
                </div>
                <div className={'EarnCard__subtitle'}>
                    {subtitle}
                </div>
            </div>
            <div className={'EarnCard__footer'}>
                <div className={'EarnCard__footer-title'}>
                    {cta}
                </div>
                <div className={'EarnCard__footer-buttons'}>
                    {children}
                </div>
                <TextLinkButton className={'EarnCard__footer-learn-more-button'}
                                target={'_blank'}
                                href={href}
                >
                    Learn more →
                </TextLinkButton>
            </div>
        </div>
    )
}

const ExchangeLogos = ({className, logos, links}) => {
    return (
        <div className={classNames('ExchangeLogos', className)}>
            {
                _.zipWith(logos, links, (logo, link) => {
                    return (
                        <a href={link}
                           target={'_blank'}
                           className={'ExchangeLogo'}
                        >
                            <img src={logo}/>
                        </a>
                    );
                })
            }
        </div>
    )
}

const SocialLogos = ({className, logos, links}) => {
    return (
        <div className={classNames('SocialLogos', className)}>
            {
                _.zipWith(logos, links, (logo, link) => {
                    return (
                        <a href={link}
                           target={'_blank'}
                           className={'SocialLogo'}
                        >
                            <img src={logo}/>
                        </a>
                    );
                })
            }
        </div>
    )
}

const LiveNetworkStat = ({name, color, amount}) => {
    return (
        <div className={classNames('LiveNetworkStat', `LiveNetworkStat--${color}`)}>
            <img src={`/images/live-network-stats/line-${color}@2x.png`}
                 className={'LiveNetworkStat__line'}
            />
            <div className={'LiveNetworkStat__data'}>
                <div className={'LiveNetworkStat__amount'}>
                    {amount}
                </div>
                <div className={'LiveNetworkStat__name'}>
                    {name}
                </div>
            </div>
        </div>
    )

};

const RoadmapColumn = ({color, title, items, arrow}) => {
    return (
        <div className={classNames('RoadmapColumn', `RoadmapColumn--${color}`)}>
            <div className={'RoadmapColumn__title'}>
                <span>{title}</span>
                <img src={arrow}/>
            </div>
            <div className={'RoadmapColumn__items'}>
                {
                    _.map(items, (item) => {
                        if(Array.isArray(item)){
                            return (
                                <div className={'RoadmapColumn__items'}>
                                    {
                                        _.map(item, (item2) => {
                                            return (
                                                <div className={'RoadmapItem RoadmapItem--indent-1'}
                                                     key={item2}
                                                >
                                                    <div>{item2}</div>
                                                </div>
                                            )
                                        })

                                    }
                                </div>
                            )
                        }
                        else{
                            return (
                                <div className={'RoadmapItem'}
                                     key={item}
                                >
                                    <div>{item}</div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
};

export const TradeSection = () => {
    const {t} = useTranslation();

    return (
        <div className={'CommunityPage__trade-section'}
             id={'trade'}
        >
            <SectionTitle title={t('community.t-section')}/>
            <ExchangeLogos
                logos={[
                    eLogosBinance, eLogosBinanceUS, eLogosHuobi, eLogosOKX,
                    eLogosGate, eLogosBithumb, eLogosUpbit, eLogosCryptoCom,
                    eLogosKorbit, eLogosKucoin, eLogosBitfinex, eLogosToko, eLogosIndodax,
                    eLogosThetaSwap, eLogosVoltSwap
                ]}
                links={[
                    ExchangesBinanceUrl, ExchangesBinanceUSUrl, ExchangesHuobiUrl, ExchangesOKXUrl,
                    ExchangesGateUrl, ExchangesBithumbUrl, ExchangesUpbitUrl, ExchangesCryptoComUrl,
                    ExchangesKorbitUrl, ExchangesKucoinUrl, ExchangesBitfinexUrl, ExchangesTokoUrl, ExchangesIndodaxUrl,
                    ExchangesThetaSwapUrl, ExchangesVoltSwapUrl
                ]}
            />
        </div>
    )
}

export const JoinTheNetworkSection = ({style, hideTitle}) => {
    const {t} = useTranslation();

    return (
        <div className={'CommunityPage__earn-section'}
             style={style}
             id={'earn-and-stake'}
        >
            {
                hideTitle ? null : <SectionTitle title={t('community.eas-section')}/>
            }
            <div className={'EarnCards'}>
                <EarningCard kind={t('earning.edge-node.kind')}
                             icon={eIconsEN}
                             title={t('earning.edge-node.title')}
                             subtitle={t('earning.edge-node.subtitle')}
                             cta={t('earning.edge-node.cta')}
                             href={DocsEdgeNodeUrl}
                >
                    <DownloadButton title={'Windows'}
                                    icon={pIconsWindows}
                                    href={DownloadsENWindowsUrl}
                    />
                    <DownloadButton title={'macOS'}
                                    icon={pIconsApple}
                                    href={DownloadsENMacOSUrl}
                    />
                    <DownloadButton title={'Linux'}
                                    icon={pIconsLinux}
                                    href={DownloadsENLinuxUrl}
                    />
                </EarningCard>

                <EarningCard kind={t('earning.guardian-node.kind')}
                             icon={eIconsGN}
                             title={t('earning.guardian-node.title')}
                             subtitle={t('earning.guardian-node.subtitle')}
                             cta={t('earning.guardian-node.cta')}
                             href={DocsGuardianNodeUrl}
                >
                    <DownloadButton title={'Windows'}
                                    icon={pIconsWindows}
                                    href={DownloadsGNWindowsUrl}
                    />
                    <DownloadButton title={'macOS'}
                                    icon={pIconsApple}
                                    href={DownloadsGNMacOSUrl}
                    />
                    <DownloadButton title={'Linux'}
                                    icon={pIconsLinux}
                                    href={DownloadsGNLinuxUrl}
                    />
                    <DownloadButton title={'CLI'}
                                    icon={pIconsCLI}
                                    href={DownloadsGNCLIUrl}
                    />
                </EarningCard>

                <EarningCard kind={t('earning.elite-edge-node.kind')}
                             icon={eIconsEEN}
                             title={t('earning.elite-edge-node.title')}
                             subtitle={t('earning.elite-edge-node.subtitle')}
                             cta={t('earning.elite-edge-node.cta')}
                             href={DocsEliteEdgeNodeUrl}
                >
                    <DownloadButton title={'Windows'}
                                    icon={pIconsWindows}
                                    href={DownloadsENWindowsUrl}
                    />
                    <DownloadButton title={'macOS'}
                                    icon={pIconsApple}
                                    href={DownloadsENMacOSUrl}
                    />
                    <DownloadButton title={'Linux'}
                                    icon={pIconsLinux}
                                    href={DownloadsENLinuxUrl}
                    />
                </EarningCard>

                <EarningCard kind={t('earning.tdrop.kind')}
                             icon={eIconsTDROP}
                             title={t('earning.tdrop.title')}
                             subtitle={t('earning.tdrop.subtitle')}
                             cta={t('earning.tdrop.cta')}
                             href={DocsTDROPUrl}
                >
                    <DownloadButton title={'iOS'}
                                    icon={pIconsApple}
                                    href={WalletsIOSUrl}
                    />
                    <DownloadButton title={'Android'}
                                    icon={pIconsAndroid}
                                    href={WalletsAndroidUrl}
                    />
                    <DownloadButton title={'Web'}
                                    icon={pIconsWeb}
                                    href={WalletsWebUrl}
                    />
                </EarningCard>

            </div>
        </div>
    );
}

const CommunityPage = () => {
    const {t} = useTranslation();
    const [liveNetworkStats, setLiveNetworkStats] = useState(null);
    const fetchLiveNetworkStats = async () => {
        //https://marketing-api.thetatoken.org/v1/news
        const response = await fetch('https://marketing-api.thetatoken.org/v1/nodes/counts');
        setLiveNetworkStats(await response.json());
    }

    useEffect(() => {
        fetchLiveNetworkStats();
    }, []);

    return (
        <div className={'Page CommunityPage'}>
            <PageSection>
                <PageBackground src={communityBackground}/>
                <HeroContent color={'blue'}
                             category={t('navbar.community')}
                             title={t('community.title')}
                />

                <SocialLogos
                    logos={[sLogosMedium, sLogosTwitter, sLogosDiscord, sLogosFacebook, sLogosGithub]}
                    links={[MediumUrl, TwitterUrl, DiscordUrl, FacebookUrl, GithubUrl]}
                />

                <div className={'CommunityPage__ctas'}>
                    <Button href={DocsWhatIsThetaUrl}
                            target={'_blank'}
                            size={'large'}
                            color={'green'}
                            className={'MainCTAButton'}
                    >
                        {t('community.cta-1')}
                    </Button>
                </div>

                <div className={'CommunityPage__live-network-stats-section'}
                     id={'live-network-stats'}
                >
                    <SectionTitle title={t('community.lns-section')}/>
                    <div className={'LiveNetworkStats'}>
                        <LiveNetworkStat name={t('common.edge-node-with-line-break')}
                                         amount={_.get(liveNetworkStats, 'totals.en', '-')}
                                         color={'yellow'}
                        />
                        <LiveNetworkStat name={t('common.guardian-node-with-line-break')}
                                         amount={_.get(liveNetworkStats, 'totals.gn', '-')}
                                         color={'green'}
                        />
                        <LiveNetworkStat name={t('common.validator-node-with-line-break')}
                                         amount={_.get(liveNetworkStats, 'totals.validator', '-')}
                                         color={'red'}
                        />
                    </div>
                </div>

                <JoinTheNetworkSection/>
                <TradeSection/>

                <div className={'CommunityPage__roadmap-section'}
                     id={'product-roadmap'}
                >
                    <SectionTitle title={t('community.pr-section')}/>

                    <div className={'Roadmap'}>
                        <RoadmapColumn color='red'
                                       arrow={rmArrowIconRed}
                                       title={t('roadmap.col1.title')}
                                       items={t('roadmap.col1.items', { returnObjects: true })}
                        />
                        <RoadmapColumn color='blue'
                                       arrow={rmArrowIconBlue}
                                       title={t('roadmap.col2.title')}
                                       items={t('roadmap.col2.items', { returnObjects: true })}
                        />
                        <RoadmapColumn color='green'
                                       arrow={rmArrowIconGreen}
                                       title={t('roadmap.col3.title')}
                                       items={t('roadmap.col3.items', { returnObjects: true })}
                        />
                        {/*<RoadmapColumn color='yellow'*/}
                        {/*               arrow={rmArrowIconYellow}*/}
                        {/*               title={t('roadmap.col4.title')}*/}
                        {/*               items={t('roadmap.col4.items', { returnObjects: true })}*/}
                        {/*/>*/}
                    </div>

                </div>

            </PageSection>

        </div>
    )
};

export const JoinTheNetworkPage = () => {
    const {t} = useTranslation();

    return (
        <div className={'Page CommunityPage'}>
            <PageSection>
                <PageBackground src={communityBackground}/>
                <HeroContent color={'blue'}
                             category={t('navbar.community')}
                             title={t('join-the-network.title')}
                />

                <JoinTheNetworkSection hideTitle={true}
                                       style={{marginTop: '0'}}
                />
                <TradeSection/>
            </PageSection>
        </div>
    )
};

export default CommunityPage;